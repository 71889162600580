<template>
    <v-app id="cad-company">
        <v-card class="elevation-12" :loading="loading" loader-height="6" :disabled="loading">
            <v-toolbar dark color="primary">
                <v-icon style="margin-right:10px;" size="30" @click="$router.go(-1)">keyboard_return</v-icon>
                <v-toolbar-title>{{ (action === 'add') ? 'Cadastrar' : 'Editar' }} estabelecimento</v-toolbar-title>
                <v-spacer />
                <!-- <v-toolbar-items>
                    <v-btn dark text @click.stop.prevent="$router.push({name: 'login'})">Voltar</v-btn>
                </v-toolbar-items> -->
            </v-toolbar>
            <v-card-text>
                <v-form class="p-l-50 p-r-50">
                    <v-row justify="center">
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="estabelecimento.nome"
                                label="Nome do estabelecimento"
                                required />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="estabelecimento.CNPJ"
                                v-maska="['###.###.###-##', '##.###.###/####-##']" label="CPF ou CNPJ" required />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field :key="mask_trigger"
                            v-model="localPhone"
                            v-maska="['(##) ####-####', '(##) # ####-####']"
                            label="Telefone" type="tel"
                            required v-on:blur="estabelecimento.telefone_1=addCountryCode(localPhone)" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="estabelecimento.email" label="E-mail" required />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-autocomplete v-model="estabelecimento.estado" :items="estados" item-text="estado"
                                item-value="estado" label="Estado" no-data-text="Nenhum estado cadastrado"
                                @change="cidadesByEstado(true)" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-autocomplete v-model="estabelecimento.cidade" :items="cidades" item-text="cidade"
                                item-value="cidade" label="Cidade" no-data-text="Nenhuma cidade cadastrada" />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="estabelecimento.rua" class="m-r-20"
                                label="Rua" required />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="estabelecimento.numero"
                                label="Número" type="tel" required />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="estabelecimento.bairro"
                                label="Bairro" required />
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="estabelecimento.nome_responsavel"
                                label="Nome do responsável" required />
                        </v-col>
                    </v-row>
                    <v-row justify="start" align="stretch" class="pa-3">
                        <v-col
                        v-if="estabelecimento.id > 0 && imageList.length < 5"
                        class="d-flex child-flex pa-1"
                        cols="4"
                        sm="3"
                        md="2"
                        >
                            <image-uploader
                            :debug="1"
                            :max-width="500"
                            :quality="0.9"
                            :auto-rotate="true"
                            output-format="string"
                            :preview="false"
                            :class-name="['fileinput']"
                            :capture="false"
                            accept="image/*"
                            do-not-resize="['gif', 'svg']"
                            @input="addImage"
                            >
                                <label slot="upload-label" for="fileInput">
                                <v-row
                                class="fill-height ma-0 grey lighten-2"
                                align="center"
                                justify="center"
                                >
                                    <v-icon x-large class="my-5">add_a_photo</v-icon>
                                </v-row>
                                </label>
                            </image-uploader>
                        </v-col>

                        <v-col
                        v-for="n in imageList"
                        :key="'image'+n"
                        class="d-flex child-flex pa-1"
                        cols="4"
                        sm="3"
                        md="2"
                        @click="removeImage(n)"
                        >
                            <v-img
                            :src="estabelecimento.imagens[n]"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            >
                                <template v-slot:placeholder>
                                <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                                >
                                    <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                    />
                                </v-row>
                                </template>
                            </v-img>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-btn dark class="ev-color-avulso float-right"
                                @click="submit">Salvar</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>

        <Snackbar :snackbar-flag="snackbar.activeFlag" :permanent="snackbar.permanentFlag" :error="snackbar.errorFlag"
            :color="snackbar.color" :hide-close-buttom="snackbar.isConfirmMode" justify-actions="end">
            <template v-slot:message>{{ snackbar.text }}</template>
            <template v-slot:actions>
                <v-btn v-if="snackbar.isConfirmMode" color="error"
                    @click.prevent="snackbar.confirm = false; snackbar.activeFlag = 0">Não
                </v-btn>
                <v-btn v-if="snackbar.isConfirmMode" color="matchpal"
                    @click.prevent="snackbar.confirm = true; snackbar.activeFlag = 0">Sim
                </v-btn>
            </template>
        </Snackbar>
    </v-app>
</template>

<script>
import Utils from "@components/Utils/Utils.vue"
import { maska } from "maska"
import ImageUploader from 'vue-image-upload-resize'

export default {
    name: "CadastroEstabelecimento",
    components: {
        Snackbar: () => import("@components/Snackbar.vue"),
        ImageUploader
    },
    directives: { maska },
    mixins: [ Utils ],
    props: {
        action: {
            type: String,
            default: 'add',
        },
    },
    data: () => ({
        estabelecimento: {},
        estados: [],
        cidades: [],
        loading: false,
        dialog: false,
        mask_trigger: 0,
        localPhone: '',
    }),
    computed: {
        imageList: function() { //Retorna os indices do array imagens que contém link
            if (this.estabelecimento.imagens) {
                return this.estabelecimento.imagens
                    .map((_,key) => key)
                    .filter(key => this.estabelecimento.imagens[key] != '')
            }
            return []
        },
    },
    mounted() {
        if(this.action === 'edit')
            this.fetchEstabelecimento()
        else
            this.estabelecimento.id = 0

        this.fetchEstados()
    },

    methods: {
        fetchEstabelecimento: function (date) {
            this.$http.post('/apiweb/getEstabelecimento').then(response => {
                if (response.data.success === true) {
                    this.estabelecimento = response.data.info
                    this.localPhone = this.removeCountryCode(this.estabelecimento.telefone)
                    ++this.mask_trigger
                } else if (response.data.error_code === 'sem_estabelecimento') {
                    this.estabelecimento.id = 0
                } else {
                    alert('ERRO: ' + response.data.message)
                    this.$router.push({ name: 'agenda' })
                }
            }).catch(error => {
                alert('Houve um erro. For favor entre em contato com o suporte técnico.')
                this.$router.push({ name: 'agenda' })
            })
        },
        fetchEstados() {
            this.$http.post('/estados').then(response => {
                this.estados = response.data
                this.cidadesByEstado(false)
            })
        },
        fetchCidades(uf, loadFirst) {
            this.$http({
                method: 'post',
                url: '/cidades/getByEstado',
                data: {
                    uf
                }
            })
                .then(response => {
                    this.cidades = response.data
                    //this.estabelecimento.cidade = this.cidades[0];
                })
                .catch(error => {
                    //alert('erro '+error);
                })
        },
        submit(event) {
            if (
                this.estabelecimento.nome &&
                this.estabelecimento.CNPJ &&
                this.localPhone &&
                this.estabelecimento.email &&
                this.estabelecimento.estado &&
                this.estabelecimento.cidade &&
                this.estabelecimento.rua &&
                this.estabelecimento.numero &&
                this.estabelecimento.bairro &&
                this.estabelecimento.nome_responsavel
            ) {
                event.preventDefault()
                this.confirm('Você tem certeza que deseja salvar alterações?').then(
                    confirm => {
                        if (!confirm) return (this.dialog = false)
                        this.$http({
                            method: 'post',
                            url: '/estabelecimentos',
                            data: {
                                estabelecimento: JSON.stringify(this.estabelecimento)
                            }
                        })
                            .then(response => {
                                if (response.data.mensagem === 'ok') {
                                    this.notify('Informações salvas com sucesso!', false)
                                    this.$router.push({ name: 'agenda' })
                                } else if (response.data.mensagem === "CNPJ existente") {
                                    this.notify('Este CPF/CNPJ já existe em nossos cadastros!', false)
                                } else {
                                    this.notify('Houve um erro! Por favor entre em contato com nosso suporte.', false)
                                }
                            })
                            .catch(error => {
                                return this.notify('Tente novamente!', true)
                            })
                        this.dialog = false
                    }
                )
            } else {
                return this.notify('Preencha todos os campos!', false)
                event.preventDefault()
            }
        },
        cidadesByEstado(loadFirst) {
            let uf = ''
            if (this.estados && this.estabelecimento.estado) {
                this.estados.forEach(item => {
                    if (this.estabelecimento.estado == item.estado) {
                        uf = item.uf
                    }
                })
                this.fetchCidades(uf, loadFirst)
            }
        },
        removeCountryCode (phone){
            return phone.slice(2)
        },
        addCountryCode (phone){
            if (!phone) return ''
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            const onlyDigits = phone.replace(/\D/g, '')
            return '55' + onlyDigits
        },
        addImage: function(output) {
            const imageData = output.split(',')
            const fistAvailable = this.estabelecimento.imagens.indexOf('') + 1
            const key = 'image' + fistAvailable

            this.$http({
                method: 'post',
                url: '/estabelecimentos/savePictures',
                data: {
                    [key]: imageData[1]
                }
            })
                .then(response => {
                    if (response.data.mensagem === 'ok') {
                        this.notify('Imagem salva com sucesso!', false)
                        this.fetchEstabelecimento()
                    } else {
                        this.notify('Houve um erro! Por favor entre em contato com nosso suporte. addImage: ' + response.data.mensagem, false)
                    }
                })
                .catch(error => {
                    return this.notify('Tente novamente!', true)
                })
        },
        removeImage: function(index) {
            const key = 'image' + (index+1)
            this.confirm('Deseja remover a imagem?').then(
                confirm => {
                    if (!confirm) return
                    this.$http({
                        method: 'post',
                        url: '/estabelecimentos/removePicture',
                        data: {
                            [key]: 'remove'
                        }
                    })
                        .then(response => {
                            console.log(response)
                            if (response.data.mensagem === 'ok') {
                                this.notify('Imagem removida com sucesso!', false)
                                this.fetchEstabelecimento()
                            } else {
                                this.notify('Houve um erro! Por favor entre em contato com nosso suporte. removeImage: ' + response.data.mensagem, false)
                            }
                        })
                        .catch(error => {
                            return this.notify('Tente novamente!', true)
                        })
                }
            )
        }
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'

    ::v-deep
        #fileInput
            display: none

    #cad-company
        .v-card__text
            padding: 16px 30px
</style>